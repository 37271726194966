.processBox {
  /* max-width: 300px; */
  margin: auto;
}

.processBox a {
  text-decoration: none;
}

.card {
  height: 200px;
}

.root-card {
  padding: 0;
  height: 200px;
}

.cardMedium {
  background-color: #e1000f;
  padding: 10px;
}

.cardMedium a,
.cardMedium a:hover,
.processTitle {
  color: #fff;
  text-decoration: none !important;
}

.processTitle {
  font-size: 22px;
}

.cardFinal {
  background-color: #999999;
}

.processActions {
  position: relative;
  top: -30px;
}
